.mrt-edit-action-buttons {
  gap: 1px !important;
}

.mrt-edit-action-buttons {
  gap: 1px !important;
}

.mrt-edit-action-buttons svg {
  width: 1rem;
}

html[dir="rtl"] .mrt-table-head-cell-resize-handle {
  right: auto !important;
  left: 0 !important;
}

.mantine-Table-thead th .mrt-table-head-sort-button {
  transition: opacity 100ms ease;
  opacity: 0;
}
.mantine-Table-thead th:hover .mrt-table-head-sort-button {
  opacity: 1;
}

.mantine-Table-thead th .mrt-table-head-cell-content-actions {
  transition: opacity 100ms ease;
  opacity: 0;
}

.mantine-Table-thead th [data-active] {
  opacity: 1;
}
.mantine-Table-thead th [data-sorted] {
  opacity: 1;
}

.mantine-Table-thead th:hover .mrt-table-head-cell-content-actions {
  opacity: 1;
}

.mantine-Table-thead th .mrt-table-head-cell-resize-handle {
  transition: opacity 100ms ease;
  opacity: 0;
}
.mantine-Table-thead th:hover .mrt-table-head-cell-resize-handle {
  opacity: 1;
}
